// stripe-connect.service.ts
import api from "config/axios.config";
import { IStripeConnect } from "./hopelappStore.type";

export const getStripeConnectUrl = (clientId: string): Promise<{ url: string }> => {
  return api.get(`/stripe/connect/clientId/${clientId}/url`);
};
export const handleStripeCallback = (code: string, state: string): Promise<any> => {
  return api.get(`/stripe/connect/callback`, {
    params: { code, state },
  });
};

// Modifier aussi votre service pour les autres routes
export const connectStripeAccount = (clientId: string, code: string): Promise<any> => {
  return api.post(`/stripe/connect/clientId/${clientId}/connect`, { code });
};

export const getConnectedAccountDetails = (accountId: string): Promise<any> => {
  return api.get(`/stripe/connect/account/${accountId}`);
};

export const disconnectAccount = (clientId: string): Promise<any> => {
  return api.post(`/stripe/connect/clientId/${clientId}/disconnect`);
};

export const getStripeAccountDetails = (accountId: string): Promise<IStripeConnect> => {
  return api.get(`/stripe/connect/account/${accountId}`);
};

export const disconnectStripeAccount = (clientId: string): Promise<{ success: boolean }> => {
  return api.post(`/stripe/connect/clientId/${clientId}/disconnect`);
};

export const getStripeAccountStatus = (accountId: string): Promise<any> => {
  return api.get(`/stripe/connect/account/${accountId}/status`);
};

export const getStripeAccountBalance = (accountId: string): Promise<any> => {
  return api.get(`/stripe/connect/account/${accountId}/balance`);
};

export const createStripePayment = (
  accountId: string,
  data: {
    amount: number;
    currency: string;
    applicationFeeAmount?: number;
  }
): Promise<any> => {
  return api.post(`/stripe/connect/account/${accountId}/payment`, data);
};
