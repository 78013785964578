import { useEffect, useState } from "react";
import { getBuildStatus } from "services/client.service";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import { Platform } from "types/client.type";

interface BuildStatusProps {
  clientId: string;
  platform: Platform;
  pollingInterval?: number;
}

const statusConfig = {
  preparing: {
    color: "warning",
    icon: "hourglass_empty",
    label: "Préparation",
  },
  building: {
    color: "info",
    icon: "engineering",
    label: "Build en cours",
  },
  completed: {
    color: "success",
    icon: "check_circle",
    label: "Terminé",
  },
  failed: {
    color: "error",
    icon: "error",
    label: "Échec",
  },
} as const;

const BuildStatus: React.FC<BuildStatusProps> = ({ clientId, platform, pollingInterval = 0 }) => {
  const [status, setStatus] = useState<{
    status: keyof typeof statusConfig;
    buildUrl?: string;
    timestamp?: string;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchStatus = async () => {
    try {
      const response = await getBuildStatus(clientId, platform);
      if (response.data?.length > 0) {
        const latestBuild = response.data[0];
        setStatus({
          status: latestBuild.status,
          buildUrl: latestBuild.buildUrl,
          timestamp: new Date(latestBuild.timestamp).toLocaleString(),
        });
      }
      setError(null);
    } catch (err) {
      setError("Erreur lors de la récupération du statut");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
    let interval: any;

    // Mettre en place le polling
    if (pollingInterval) interval = setInterval(fetchStatus, pollingInterval);

    // Cleanup
    return () => clearInterval(interval);
  }, [clientId, platform, pollingInterval]);

  if (isLoading) {
    return (
      <MDBox display="flex" alignItems="center">
        <Icon>refresh</Icon>
        <MDTypography variant="button" ml={1}>
          Chargement...
        </MDTypography>
      </MDBox>
    );
  }

  if (error) {
    return (
      <MDBox display="flex" alignItems="center" color="error">
        <Icon>warning</Icon>
        <MDTypography variant="button" color="error" ml={1}>
          {error}
        </MDTypography>
      </MDBox>
    );
  }

  if (!status) {
    return (
      <MDBox display="flex" alignItems="center">
        <Icon>info</Icon>
        <MDTypography variant="button" ml={1}>
          Aucun build trouvé
        </MDTypography>
      </MDBox>
    );
  }

  const config = statusConfig[status.status];

  return (
    <MDBox>
      <MDBox display="flex" alignItems="center" mb={1}>
        <Icon color={config.color}>{config.icon}</Icon>
        <MDTypography variant="button" color={config.color} ml={1}>
          {config.label}
        </MDTypography>
        {status.timestamp && (
          <MDTypography variant="caption" color="text" ml={2}>
            {status.timestamp}
          </MDTypography>
        )}
      </MDBox>

      {/* NE SERA JAMAIS UTILISER, TROP TECHNIQUE */}
      {status.buildUrl && (
        <MDButton
          component="a"
          href={status.buildUrl}
          target="_blank"
          rel="noopener noreferrer"
          variant="outlined"
          size="small"
          startIcon={<Icon>launch</Icon>}
        >
          Voir sur GitHub
        </MDButton>
      )}
    </MDBox>
  );
};

export default BuildStatus;
