import { Design } from "pages/design/design.type";
import { ETemplate } from "./design.type";
import { EFeatures } from "./features.type";

export interface IClient {
  readonly _id?: string;
  name?: string;
  code?: string;
  logo?: string;
  email?: string;
  phoneAppName?: string;
  shortDesc?: string;
  longDesc?: string;
  keywords?: string;
  features?: Features;
  design?: Design;
  menu?: [];
  description?: string;
  socials?: ISocials;
  settings?: ISettings;
  address?: IAddressClient;
  readonly template?: Template;
  readonly createdAt?: string;
  updatedAt?: Date;
  openingHours?: [OpeningHours];
}
export interface OpeningHours {
  day: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
  open: string;
  close: string;
}
export interface ICreateClient {
  name: string;
  email: string;
  activity: string;
  description: string;
  template: ETemplate;
}

export const SELECT_CUSTOMERS = "id,name,logo,statusClient,statusApp,createdAt,code";
export const SELECT_INFORMATION =
  "id,name,logo,description,keywords,design,socials,address,settings,openingHours";
export const SELECT_FEATURES = "features";
export const SELECT_DESIGN = "name,logo,design,menu";

export enum StatusClient {
  PROSPECT = "Prospect",
  CLIENT = "Client",
}

export enum StatusApp {
  TEST = "Test",
  STAGING = "Staging",
  DEPLOYMENT = "Deployment",
}

export type DataFeatures = {
  enabled: boolean;
  key?: string;
};

export type Features = {
  [featureName in EFeatures]: DataFeatures;
};
export interface ISocials {
  webSite: string;
  twitter: string;
  facebook: string;
  instagram: string;
  linkedin: string;
}
export interface IStripeConnect {
  requirements: string[];
  accountId: string;
  enabled: boolean;
  chargesEnabled: boolean;
  detailsSubmitted: boolean;
  defaultCurrency: string;
  country: string;
}

export interface ISettings {
  currency?: ECurrency;
  statusApp?: EStatusApp;
  statusClient?: EStatusClient;
  statusEnv?: EStatusEnv;
  stripeConnect?: IStripeConnect;
}
export interface IAddressClient {
  address: string;
  link: string;
}

export interface Template {
  category: ETemplate;
  images: [string];
}

export enum EStatusClient {
  PROSPECT = "Prospect",
  CLIENT = "Client",
}

export enum EStatusEnv {
  TEST = "Test",
  PRODUCTION = "Production",
}

export enum EStatusApp {
  BLOCKED = "blocked", // Blocked by superAdmin essentially
  DEACTIVATE = "deactivate",
  MAINTENANCE = "maintenance",
  DELETE = "delete",
  ACTIVATE = "activate",
}

export enum ECurrency {
  EUR = "EUR",
  CAD = "CAD",
}

export type Platform = "ios" | "android";

export interface IVerifyBuildResponse {
  success: boolean;
  canBuild: boolean;
  missingRequirements?: string[];
  message?: string;
}

export interface ITriggerBuildResponse {
  success: boolean;
  message: string;
  buildId?: string;
  workflowUrl?: string;
}
