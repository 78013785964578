import checkout from "pages/element/schemas/product/form";

const {
  formField: {
    name,
    title,
    category,
    description,
    image,
    price,
    discount,
    deposit,
    file,
    attributes,
    inCarousel,
    productActionType,
    link,
    isMultipleReservation,
    isAutomaticConfirmation,
    durationDays,
    durationHours,
    durationMinutes,
    typeElement,
    isVisible,
    isAvailable,
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [title.name]: "",
  [category.name]: "",
  [price.name]: "",
  [discount.name]: "",
  [deposit.name]: "",
  [description.name]: "",
  [image.name]: "",
  [file.name]: "",
  [attributes.name]: "",
  [inCarousel.name]: false,
  [productActionType.name]: "",
  [link.name]: "",
  [isMultipleReservation.name]: false,
  [isAutomaticConfirmation.name]: false,
  [durationDays.name]: 0,
  [durationHours.name]: 0,
  [durationMinutes.name]: 0,
  [typeElement.name]: "",
  [isVisible.name]: false,
  [isAvailable.name]: false,
};

export default initialValues;
