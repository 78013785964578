import React, { ReactNode } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
import { ISection } from "pages/design/design.type";

interface DraggableSectionsProps {
  sections: ISection[];
  onReorder: (newSections: ISection[]) => void;
  children: ReactNode;
}

const DraggableSections: React.FC<DraggableSectionsProps> = ({ sections, onReorder, children }) => {
  const handleDragEnd = (result: DropResult): void => {
    if (!result.destination) return;

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedSections = items.map((section, index) => ({
      ...section,
      order: index,
    }));

    onReorder(updatedSections);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="sections">
        {(provided: any) => (
          <div
            {...provided.droppableProps}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
            ref={provided.innerRef}
            className="space-y-4"
          >
            {React.Children.map(children, (child, index) => (
              <Draggable
                key={`${sections[index].type}-${index}`}
                draggableId={`${sections[index].type}-${index}`}
                index={index}
              >
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      opacity: snapshot.isDragging ? 0.8 : 1,
                    }}
                  >
                    <Card
                      sx={{
                        position: "relative",
                        transition: "all 0.2s",
                        "&:hover": {
                          backgroundColor: "#fafafa",
                          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                          cursor: "grab",
                          "& .drag-handle": {
                            opacity: 1,
                            backgroundColor: "#f0f0f0",
                            borderRadius: "4px",
                          },
                          "& .drag-hint": {
                            opacity: 1,
                          },
                        },
                        "&:active": {
                          cursor: "grabbing",
                        },
                      }}
                    >
                      <div style={{ paddingLeft: "25px" }}>{child}</div>
                      <div
                        {...provided.dragHandleProps}
                        className="drag-handle"
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "grab",
                          opacity: 0.3,
                          transition: "all 0.2s",
                          padding: "4px",
                        }}
                      >
                        <Icon>drag_indicator</Icon>
                      </div>
                      <div
                        className="drag-hint"
                        style={{
                          position: "absolute",
                          left: "40px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          fontSize: "12px",
                          color: "#666",
                          opacity: 0,
                          transition: "opacity 0.2s",
                          pointerEvents: "none",
                        }}
                      ></div>
                    </Card>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableSections;
