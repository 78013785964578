import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useConnectStripeAccount } from "../../hopelappStore.hook";

const StripeCallback = () => {
  const navigate = useNavigate();
  const connectStripe = useConnectStripeAccount();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (code && state) {
      console.log("state:", state);
      console.log("code:", code);
      try {
        const decodedState = atob(state); // Decode base64 state
        const { clientId } = JSON.parse(decodedState);
        console.log("clientId:", clientId);

        connectStripe.mutate(
          { code, clientId },
          {
            onSuccess: () => navigate("/store?stripeConnected=true"),
            onError: () => navigate("/store?error=stripe-connection-failed"),
          }
        );
      } catch (error) {
        console.log("error:", error);
        navigate("/store?error=invalid-state");
      }
    } else {
      navigate("/store?error=missing-parameters");
    }
  }, []);

  return (
    <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
      <CircularProgress />
    </MDBox>
  );
};

export default StripeCallback;
