import Icon from "@mui/material/Icon";
import profilePicture from "assets/images/profil-default.png";
import MDAvatar from "components/MDAvatar";
import { User } from "firebase/auth";
import i18next from "i18n";
import ResetCover from "pages/auth/ResetPassword";
import SignIn from "pages/auth/SignIn";
import SignUp from "pages/auth/SignUp";
import VerifyEmail from "pages/auth/VerifyEmail";
import CreateApp from "pages/create-app/pages/Create-app";
import Dashboard from "pages/dashboard";
import Design from "pages/design";
import Element from "pages/element";
import AddProduct from "pages/element/pages/AddProduct";
import Logout from "pages/auth/logout";
import AppPublishing from "pages/publishing";
import AdminComponent from "pages/admin";
import { Features } from "types/client.type";
import { EFeatures } from "types/features.type";
import EditProduct from "pages/element/pages/EditProduct";
import HopelappStore from "pages/hopelappStore";
import StripeCallback from "pages/hopelappStore/components/Stripe/StripeCallback";
import MyApp from "pages/myApp";
import TechnicalService from "pages/technicalService";
import ThankYou from "pages/thank-you";
import { Admin } from "types/admin.type";
import Chat from "pages/chat";
import { Route } from "types/route.type";

const createFeatureRoutes = (): Map<EFeatures, Route> => {
  const featureRoutes = new Map();

  featureRoutes.set(EFeatures.CHAT, {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">chat</Icon>,
    name: i18next.t("navbar.chat"),
    key: "chat",
    route: "/chat",
    component: <Chat />,
    privateRoute: true,
  });

  return featureRoutes;
};

const createMainRoutes = (isSuperAdmin: boolean): Route[] => [
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">feed</Icon>,
    name: i18next.t("navbar.myApp"),
    key: "myApp",
    route: "/myApp",
    component: <MyApp />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    name: i18next.t("navbar.element"),
    key: "element",
    route: "/element",
    component: <Element />,
    privateRoute: true,
  },
  {
    noCollapse: true,
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    name: i18next.t("navbar.edit"),
    key: "element-edit",
    route: "/element/edit",
    component: <EditProduct />,
    privateRoute: true,
  },
  {
    noCollapse: true,
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    name: i18next.t("navbar.add"),
    key: "element-new",
    route: "/element/new",
    component: <AddProduct />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">inbox</Icon>,
    name: i18next.t("navbar.dashboard"),
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    name: i18next.t("navbar.design"),
    key: "design",
    route: "/design",
    component: <Design />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">widgets</Icon>,
    name: i18next.t("navbar.store"),
    key: "store",
    route: "/store",
    component: <HopelappStore />,
    privateRoute: true,
  },
  ...(isSuperAdmin
    ? [
        {
          type: "collapse" as const,
          noCollapse: true,
          icon: <Icon fontSize="medium">rocket_launch</Icon>,
          name: i18next.t("navbar.application-publishing"),
          key: "application-publishing",
          route: "/application-publishing",
          component: <AppPublishing />,
          privateRoute: true,
        },
        {
          type: "collapse" as const,
          noCollapse: true,
          icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
          name: i18next.t("navbar.admin"),
          key: "admin",
          route: "/admin",
          component: <AdminComponent />,
          privateRoute: true,
        },
      ]
    : []),
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">live_help</Icon>,
    name: i18next.t("navbar.technical-support"),
    key: "technical-support",
    route: "/deployment",
    component: <TechnicalService />,
    privateRoute: true,
  },
  {
    noCollapse: true,
    icon: <Icon fontSize="medium">content_paste</Icon>,
    name: "Create App",
    key: "create-app",
    route: "/create/app",
    component: <CreateApp />,
    privateRoute: true,
  },
  {
    noCollapse: true,
    icon: <Icon fontSize="medium">content_paste</Icon>,
    name: "Stripe Callback",
    key: "stripe-callback",
    route: "/store/stripe-callback",
    component: <StripeCallback />,
    privateRoute: true,
  },
];

const createAuthenticationRoutes = (): Route => ({
  name: "Authentication",
  key: "authentication",
  icon: <Icon fontSize="medium">content_paste</Icon>,
  collapse: [
    {
      name: "Sign In",
      key: "sign-in",
      collapse: [
        {
          name: "Basic",
          key: "basic",
          route: "/authentication/sign-in",
          component: <SignIn />,
        },
      ],
    },
    {
      name: "Sign Up",
      key: "sign-up",
      collapse: [
        {
          name: "Cover",
          key: "cover",
          route: "/authentication/sign-up",
          component: <SignUp />,
        },
      ],
    },
    {
      name: "Reset Password",
      key: "reset-password",
      collapse: [
        {
          name: "Cover",
          key: "cover",
          route: "/authentication/reset-password",
          component: <ResetCover />,
        },
      ],
    },
    {
      name: "Thank you",
      key: "thank-you",
      collapse: [
        {
          name: "Cover",
          key: "cover",
          route: "/thank-you",
          component: <ThankYou />,
        },
      ],
    },
    {
      name: "Verify email",
      key: "verify-email",
      collapse: [
        {
          name: "Cover",
          key: "cover",
          route: "/authentication/verify-email",
          component: <VerifyEmail />,
          privateRoute: true,
        },
      ],
    },
  ],
});

const routes = (currentAdmin?: Admin, currentUserFirebase?: User, features?: Features): Route[] => {
  const isSuperAdmin = currentAdmin?.role.includes("superadmin");
  const featureRoutes = createFeatureRoutes();

  const enabledFeatures = features
    ? Object.entries(features)
        .filter(([key, value]) => value.enabled && featureRoutes.has(key as EFeatures))
        .map(([key]) => featureRoutes.get(key as EFeatures))
    : [];

  return [
    {
      type: "collapse",
      name: currentAdmin?.lastName,
      key: "user-profile",
      icon: (
        <MDAvatar
          src={currentUserFirebase?.photoURL || profilePicture}
          alt={currentAdmin?.lastName || "User"}
          size="sm"
        />
      ),
      collapse: [
        {
          name: i18next.t("navbar.logout"),
          key: "logout",
          route: "/logout",
          component: <Logout />,
        },
      ],
    },
    { type: "divider", key: "divider-0" },
    { type: "title", title: "Pages", key: "title-pages" },
    ...createMainRoutes(isSuperAdmin),
    { type: "divider", key: "divider-1" },
    ...enabledFeatures,
    createAuthenticationRoutes(),
  ];
};

export default routes;
