import Modal from "components/Modal/modal";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import BuildButton from "pages/admin/components/Build/BuildButton";
import ClientSelector from "components/SelectClient/ClientSelector";
import { AuthContext } from "context/auth-context/auth.context";
import RolesType from "types/roles.type";
import BuildStatus from "pages/admin/components/Build/BuildStatus";

const Index = () => {
  const { t } = useTranslation();
  const { currentUserFirebase, currentAdmin, clientId } = useContext(AuthContext);
  const isSuperAdmin = currentAdmin?.role?.includes(RolesType.SUPER_ADMIN);

  const [openModal, setOpenModal] = useState(false);

  const [notification, setNotification] = useState<{
    show: boolean;
    message: string;
    color: "success" | "error";
  }>({ show: false, message: "", color: "success" });

  // Vérifier les paramètres d'URL au chargement
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get("stripeConnected") === "true") {
      setNotification({
        show: true,
        message: t("stripe.notification.connectSuccess"),
        color: "success",
      });
    } else if (params.get("error") === "stripe-connection-failed") {
      setNotification({
        show: true,
        message: t("stripe.notification.connectError"),
        color: "error",
      });
    }
  }, [location.search]);

  const renderModalConfirm = (
    <Modal
      title={t("element.modalConfirm.title")}
      openModal={openModal}
      body={<>coucou</>}
      handleAction={() => setOpenModal(false)}
      handleClose={() => setOpenModal(false)}
      valid={false}
      action={true}
      actionStyle="warning"
      actionLabel={t("general.yes")}
    />
  );

  const renderNotification = notification.show && (
    <MDBox
      mb={2}
      p={2}
      bgcolor={notification.color === "success" ? "success.light" : "error.light"}
      borderRadius="lg"
    >
      <MDTypography variant="body2" color="white">
        {notification.message}
      </MDTypography>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderNotification}
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card id="profile">
                    <MDBox p={2}>
                      <Grid spacing={3} alignItems="center">
                        <Grid item>{t("admin.title")}</Grid>
                      </Grid>
                    </MDBox>
                    <MDBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid container ml={4}>
                          {isSuperAdmin && <ClientSelector />}
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card id="profile">
                    <MDBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>N/A</Grid>
                        <Grid item>
                          <MDBox height="100%">
                            <MDTypography variant="h6" fontWeight="medium">
                              IOS
                            </MDTypography>
                            <MDTypography variant="caption" fontWeight="regular">
                              version : 1
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item>
                          <MDBox height="100%">
                            <MDTypography variant="caption" fontWeight="regular">
                              {t("common.status")}:
                            </MDTypography>
                            <BuildStatus clientId={clientId} platform={"ios"} />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }} margin={2}>
                          <MDBox
                            display="flex"
                            justifyContent={{ md: "flex-end" }}
                            alignItems="center"
                            lineHeight={1}
                          >
                            <BuildButton
                              clientId={clientId}
                              platform={"ios"}
                              onSuccess={() => {
                                // Optionnel : forcer le rafraîchissement du status
                              }}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>N/A</Grid>
                        <Grid item>
                          <MDBox height="100%">
                            <MDTypography variant="h6" fontWeight="medium">
                              Android
                            </MDTypography>
                            <MDTypography variant="caption" fontWeight="regular">
                              version : 1
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item>
                          <MDBox height="100%">
                            <MDTypography variant="caption" fontWeight="regular">
                              {t("common.status")}:
                            </MDTypography>
                            <BuildStatus clientId={clientId} platform={"android"} />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }} margin={2}>
                          <MDBox
                            display="flex"
                            justifyContent={{ md: "flex-end" }}
                            alignItems="center"
                            lineHeight={1}
                          >
                            <BuildButton
                              clientId={clientId}
                              platform={"android"}
                              onSuccess={() => {
                                // Optionnel : forcer le rafraîchissement du status
                              }}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {renderModalConfirm}
      <Footer />
    </DashboardLayout>
  );
};

export default Index;
