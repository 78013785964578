import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Navigation from "components/NavigationPage/Navigation";
import { useTranslation } from "react-i18next";
import Appointment from "pages/appointment";
import Order from "pages/order";
import Rent from "pages/rent";
import { useGetClientById } from "pages/client/client.hook";
import { SELECT_FEATURES } from "types/client.type";
import { EFeatures } from "types/features.type";
import StripeDashboardTab from "pages/hopelappStore/components/Stripe/StripeDashboardTab";

const Index = () => {
  const { t } = useTranslation();

  // #DOC Init feature in this page
  const featureUsedObj = new Map();
  featureUsedObj.set(EFeatures.BOOKINGAPPOINTMENT, <Appointment />);
  featureUsedObj.set(EFeatures.RENT, <Rent />);
  featureUsedObj.set(EFeatures.ONLINEPAYMENT, <StripeDashboardTab />);

  const { data } = useGetClientById(SELECT_FEATURES);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {data ? (
        <Navigation
          stickyNavbar={true}
          tabs={[
            { label: t("order.tabs.order"), content: <Order />, mode: "inside" },
            ...Object.entries(data.features)
              .filter(([key, value]) => {
                return [...featureUsedObj.keys()]
                  .filter(() => value.enabled)
                  .includes(key as EFeatures);
              })
              .map(([key]) => {
                return {
                  label: t(`appointment.tabs.${key}`),
                  content: featureUsedObj.get(key as EFeatures),
                  mode: "inside",
                };
              }),
          ]}
        >
          <Footer />
        </Navigation>
      ) : null}
    </DashboardLayout>
  );
};

export default Index;
