import { useEffect, useRef, useState } from "react";
import Board from "@asseinfo/react-kanban";
import parse from "html-react-parser";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useGetClientById } from "pages/client/client.hook";
import { ECurrency, SELECT_INFORMATION } from "types/client.type";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Modal from "components/Modal/modal";
import { useTranslation } from "react-i18next";
import Card from "layouts/order-kanban/components/Card";
import { useMaterialUIController } from "context/materialUI-context/materialUI.context";
import ModalInfoOrder from "pages/order/components/ModalInfoOrder";
import { useGetAllOrder, useUpdateOrder } from "pages/order/order.hook";
import { IOrder, IStatusOrder } from "pages/order/order.type";
import { ETypeAction } from "pages/element/element.type";

interface CardProps {
  id: string;
  template: any;
}
interface ColumnProps {
  id: string;
  title: IStatusOrder | string;
  cards: CardProps[];
}

interface BoardProps {
  columns: ColumnProps[];
}

function Kanban(): JSX.Element {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const information = useGetClientById(SELECT_INFORMATION);
  const { data, isLoading } = useGetAllOrder();
  const [loading, setLoading] = useState<boolean>(isLoading);
  const [freeze, setFreeze] = useState<boolean>(false);
  const updateOrder = useUpdateOrder();

  const [newCardForm, setNewCardForm] = useState<string | number | boolean>(false);
  const [formValue, setFormValue] = useState<string>("");
  const [groupedBoard, setGroupedBoard] = useState<BoardProps>(null);

  const [typeAction, setTypeAction] = useState<ETypeAction>(ETypeAction.CREATE);
  const modalCreateOrderRef = useRef(null);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [selectOrder, setSelectOrder] = useState<IOrder | null>(null);

  const closeNewCardForm = () => setNewCardForm(false);
  const handeSetFormValue = ({ currentTarget }: any) => setFormValue(currentTarget.value);

  const currency = (
    information?.data?.settings?.stripeConnect?.defaultCurrency ||
    information?.data?.settings?.currency ||
    ECurrency.EUR
  ).toLocaleLowerCase() as ECurrency;

  function groupOrder(orders: any) {
    const board: BoardProps = {
      columns: [
        {
          id: IStatusOrder.PENDING,
          title: t("order.status.pending"),
          cards: [],
        },
        {
          id: IStatusOrder.INPROGRESS,
          title: t("order.status.inProgress"),
          cards: [],
        },
        {
          id: IStatusOrder.DONE,
          title: t("order.status.done"),
          cards: [],
        },
      ],
    };

    for (let index = 0; index < orders.length; index++) {
      const element = orders[index];
      board.columns
        .find((x) => x.id == element.status)
        ?.cards.push({
          id: element._id,
          template: (
            <Card
              image={null}
              badge={{ color: "info", label: moment(element.createdAt).format("MM/DD/YYYY") }}
              name={element.name}
              email={element.email}
              numberOfProducts={element.basket.reduce((a: any, c: any) => a + c["quantity"], 0)}
              totalPrice={element.basket.reduce(
                (a: any, c: any) => +(a + parseFloat(c["quantity"]) * parseFloat(c["price"])),
                0.0
              )}
              images={element.basket.map((y: any) => y.url).filter(Boolean)}
              currency={currency || "€"}
              onClick={() => {
                handleActionUpdate(element);
              }}
            />
          ),
        });
    }
    return board;
  }

  function findChangedCards(oldArray: BoardProps, newArray: BoardProps) {
    const cardIds: string[] = [];
    const changedCards: any[] = [];

    newArray.columns.forEach((newColumn: ColumnProps, x: number) => {
      const oldColumn: ColumnProps = oldArray.columns[x];

      oldColumn.cards.forEach((oldCard) => {
        if (!newColumn.cards.map((x) => x.id).includes(oldCard.id)) {
          cardIds.push(oldCard.id);
        }
      });
    });
    newArray.columns.forEach((newColumn: ColumnProps) => {
      newColumn.cards.forEach((card) => {
        if (cardIds.includes(card.id)) {
          changedCards.push({
            orderId: card.id,
            values: {
              statut:
                newColumn.id == "inProgress"
                  ? IStatusOrder.INPROGRESS
                  : newColumn.id == "pending"
                  ? IStatusOrder.PENDING
                  : IStatusOrder.DONE,
            },
          });
        }
      });
    });
    return changedCards;
  }
  async function sendUpdate(event: BoardProps) {
    setLoading(true);
    const res = findChangedCards(groupedBoard, event);
    try {
      for (const card of res) {
        await updateOrder.mutateAsync({
          orderId: card.orderId,
          data: { status: card.values.statut },
        });
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      changeLoading(false);
    }
  }

  function changeLoading(state: boolean) {
    setFreeze(true);
    setTimeout(() => {
      setLoading(state);
      setFreeze(false);
    }, 1000);
  }
  useEffect(() => {
    setLoading(true);
    let board;
    if (data) board = groupOrder(data);

    setGroupedBoard(board);
    if (!freeze) setLoading(false);
  }, [data]);

  const handleActionUpdate = (order: IOrder) => {
    setSelectOrder(order);
    setTypeAction(ETypeAction.UPDATE);
    setOpenModalCreate(true);
  };

  const handleValidSubmit = async () => {
    if (modalCreateOrderRef.current) return await modalCreateOrderRef.current.submitForm();
  };

  const renderCreateOrUpdateOrder = (
    <Modal
      title={t(`order.modal.${typeAction}.title`)}
      openModal={openModalCreate}
      body={<ModalInfoOrder currentOrder={selectOrder} ref={modalCreateOrderRef} />}
      valid={false}
      handleValid={handleValidSubmit}
      handleClose={() => setOpenModalCreate(false)}
    />
  );

  return (
    <MDBox py={3}>
      <MDBox display="flex" justifyContent="flex-end" m={2}>
        {loading ? "..." : ""}
      </MDBox>
      <MDBox
        position="relative"
        my={4}
        sx={({
          palette: { light, background },
          functions: { pxToRem },
          borders: { borderRadius },
        }: Theme | any) => ({
          "& .react-kanban-column": {
            backgroundColor: darkMode ? background.card : light.main,
            width: pxToRem(450),
            margin: `0 ${pxToRem(10)}`,
            padding: pxToRem(20),
            borderRadius: borderRadius.lg,
          },
        })}
      >
        {groupedBoard ? (
          <Board
            initialBoard={groupedBoard}
            allowAddCard={false}
            allowAddColumn={false}
            onCardDragEnd={(event: any) => sendUpdate(event)}
            renderColumnHeader={({ id, title }: any, { addCard }: any) => (
              <>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                  <MDTypography variant="h6">{title}</MDTypography>
                  <MDButton
                    size="small"
                    iconOnly
                    // onClick={(event) => alert(event) /*openNewCardForm(event, id)*/}
                  >
                    <Icon
                      sx={{
                        fontWeight: "bold",
                        color: ({ palette: { dark } }) => dark.main,
                      }}
                    >
                      add
                    </Icon>
                  </MDButton>
                </MDBox>
                {newCardForm === id ? (
                  <MDBox my={2.5}>
                    <MDInput
                      value={formValue}
                      rows="4"
                      onChange={handeSetFormValue}
                      multiline
                      fullWidth
                    />
                    <MDBox display="flex" mt={2}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        size="small"
                        onClick={() => {
                          addCard({ id: uuidv4(), template: formValue });
                          setFormValue("");
                        }}
                      >
                        add
                      </MDButton>
                      <MDBox ml={1}>
                        <MDButton
                          variant="gradient"
                          color="light"
                          size="small"
                          onClick={closeNewCardForm}
                        >
                          cancel
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                ) : null}
              </>
            )}
            renderCard={({ id, template }: any, { dragging }: any) => (
              <MDBox
                key={id}
                dragging={dragging.toString() || undefined}
                display="block"
                width="calc(450px - 40px)"
                bgColor={darkMode ? "transparent" : "white"}
                color="text"
                borderRadius="xl"
                mt={2.5}
                py={1.875}
                px={1.875}
                lineHeight={1.5}
                sx={{
                  border: ({ borders: { borderWidth }, palette: { white } }: any) =>
                    darkMode ? `${borderWidth[1]} solid ${white.main}` : 0,
                  fontSize: ({ typography: { size } }: any) => size.md,
                }}
              >
                {typeof template === "string" ? parse(template) : template}
              </MDBox>
            )}
            onCardNew={(): any => {}}
          />
        ) : (
          ""
        )}
        {renderCreateOrUpdateOrder}
        {/*loading && <LoadingModal></LoadingModal>*/}
      </MDBox>
    </MDBox>
  );
}

export default Kanban;
