import { CircularProgress } from "@mui/material";
import Modal from "components/Modal/modal";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetStripeAccount } from "../../hopelappStore.hook";
import StripeSettingsModal from "pages/hopelappStore/components/Stripe/StripeSettingsModal";
import { useTranslation } from "react-i18next";

const StripeCallback = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getStripeAccount = useGetStripeAccount();

  const [renderModal, setRenderModal] = useState(false);

  useEffect(() => {
    try {
      console.log("getStripeAccount:", getStripeAccount);

      if (getStripeAccount.data) {
        navigate("https://dashboard.stripe.com");
      } else {
        // navigate("/store?error=stripe-connection-failed");
        setRenderModal(true);
      }
    } catch (error) {
      console.log("error:", error);
      navigate("/store?error=invalid-state");
    }
  }, []);

  return renderModal ? (
    <Modal
      title={t(`hopelappStore.onlinePayment.label`)}
      openModal={renderModal}
      body={<StripeSettingsModal />}
      handleClose={() => {
        setRenderModal(false);
        navigate("/");
      }}
      valid={false}
    />
  ) : (
    <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
      <CircularProgress />
    </MDBox>
  );
};

export default StripeCallback;
