import MDBox from "components/MDBox";
import { ISection } from "pages/design/design.type";
import { memo, useMemo } from "react";

interface Props {
  section?: ISection;
}

const MIN_HEIGHT = 25;
const MAX_HEIGHT = 300;
const DEFAULT_HEIGHT = 200;

const MockupSectionImage = memo(({ section }: Props): JSX.Element => {
  const isBlob = section.imageUrl instanceof Blob;

  const imageHeight = useMemo(() => {
    if (!section || typeof section.imageHeight !== "number") {
      return DEFAULT_HEIGHT;
    }
    return Math.min(Math.max(section.imageHeight, MIN_HEIGHT), MAX_HEIGHT);
  }, [section]);

  return (
    <MDBox p={0}>
      <MDBox
        component="img"
        // src={imageUrl}
        src={isBlob ? URL.createObjectURL(section.imageUrl) : section.imageUrl}
        alt="section image"
        sx={{
          width: "100%",
          height: `${imageHeight}px`,
          objectFit: "cover",
          objectPosition: "center",
          display: "block",
        }}
      />
    </MDBox>
  );
});

export default MockupSectionImage;
