import { CircularProgress, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetStripeConnectUrl, useGetStripeAccountStatus } from "../../hopelappStore.hook";

// Dans votre composant de configuration Stripe
const StripeSettingsModal: React.FC = () => {
  const { t } = useTranslation();
  const getConnectUrl = useGetStripeConnectUrl();
  const getStripeAccountStatus = useGetStripeAccountStatus();

  const handleConnect = () => {
    getConnectUrl.mutate();
  };

  return (
    <MDBox>
      <Stack spacing={3}>
        <MDTypography variant="h6" fontWeight="medium">
          {t("hopelappStore.onlinePayment.configuration.title")}
        </MDTypography>

        <MDBox>
          <MDTypography variant="body2" color="text">
            {t("hopelappStore.onlinePayment.configuration.description")}
          </MDTypography>
        </MDBox>

        {getStripeAccountStatus.data ? getStripeAccountStatus.data : "..."}

        <MDButton
          variant="gradient"
          color="info"
          onClick={handleConnect}
          disabled={getConnectUrl.isLoading}
          fullWidth
        >
          {getConnectUrl.isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("hopelappStore.onlinePayment.configuration.connect")
          )}
        </MDButton>
      </Stack>
    </MDBox>
  );
};

export default StripeSettingsModal;
