import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";

interface Props {
  formData: any;
}

const GeneralUpdate = ({ formData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { category, description } = formField;
  const { description: descriptionV, category: categoryV } = values;
  const activityItems = t("technicalService.itemCategory", { returnObjects: true }) as any;
  const activityItemsArray = activityItems.map((item: string) => item);

  return (
    <>
      <Card style={{ maxHeight: "750px", overflowY: "auto" }}>
        <MDBox p={3}>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  options={activityItemsArray}
                  onChange={(e, value) => {
                    setFieldValue(category.name, value !== null ? value : "");
                  }}
                  onInputChange={(e, value) => {
                    setFieldValue(category.name, value !== null ? value : "");
                  }}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      type={category.type}
                      label={t("technicalService.category")}
                      name={category.name}
                      value={categoryV}
                      placeholder={category.placeholder}
                      error={errors.category && touched.category}
                      success={categoryV.length > 0 && !errors.category}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={description.type}
                  label={t("createApp.descriptionApp")}
                  name={description.name}
                  value={descriptionV}
                  placeholder={description.placeholder}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
            {/*<MDBox>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                {t("form.image")}
              </MDTypography>
              {useMemo(
                () => (
                  <MDDropzone
                    options={{
                      addRemoveLinks: false,
                      maxFiles: 1,
                      acceptedFiles: "image/png, image/jpg, image/jpeg",
                    }}
                    onChange={(fileImage) => setFieldValue(image.name, fileImage)}
                  />
                ),
                []
              )}
                  </MDBox>*/}
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default GeneralUpdate;
