import { Card, Divider, Grid } from "@mui/material";
import CardProduct from "components/CardProduct/CardProduct";
import InfoBox from "components/InfoBox/InfoBox";
import MDBox from "components/MDBox";
import { useGetClientById } from "pages/client/client.hook";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ECurrency, SELECT_INFORMATION } from "types/client.type";
import { IOrder } from "../order.type";

interface Props {
  currentOrder?: IOrder;
}

const ModalInfoOrder = forwardRef(({ currentOrder }: Props) => {
  const { t } = useTranslation();
  const { data } = useGetClientById(SELECT_INFORMATION);
  const subtotal = currentOrder?.basket?.reduce((total, item) => {
    total += (parseFloat(item.price) || 0) * (item.quantity || 1);
    return total;
  }, 0);

  const currency = (
    data?.settings?.stripeConnect?.defaultCurrency ||
    data?.settings?.currency ||
    ECurrency.EUR
  ).toLocaleLowerCase() as ECurrency;

  return (
    <MDBox mt={1} mb={1} ml={0.5}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible",
        }}
      >
        <MDBox>
          <Grid container spacing={3}>
            {currentOrder.basket.map((element) => (
              <Grid key={element.productId} item xs={12} sm={12}>
                <CardProduct {...element} />
              </Grid>
            ))}
          </Grid>
        </MDBox>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <InfoBox title={t("form.name")} value={currentOrder.name} />
            <InfoBox title={t("form.email")} value={currentOrder.email} />
            <InfoBox title={t("form.message")} value={currentOrder.message} />
          </Grid>
          <Grid item xs={12} sm={3} container justifyContent="flex-end" alignItems="center">
            <InfoBox
              title={t("common.total")}
              value={`${subtotal?.toFixed(2)} ${currency === ECurrency.EUR ? "€" : "$"}`}
            />
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
});

export default ModalInfoOrder;
