import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Icon } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SelectColor from "components/Select/SelectColor";
import MDEditor from "components/MDEditor";
import { ISection } from "pages/design/design.type";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  section: ISection;
  setFieldValue: any;
  index: number;
  handleDeleteSection: (index: number) => void;
}

const SectionText = memo(
  ({ section, setFieldValue, index, handleDeleteSection }: Props): JSX.Element => {
    const { t } = useTranslation();
    const { bgColor, textColor, htmlContent } = section;
    const [defaultText] = useState(htmlContent);

    return (
      <MDBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <MDTypography variant="body2">{t("design.section.text.title")}</MDTypography>
              </Grid>
              <Grid item>
                <MDBox
                  color="error"
                  size="medium"
                  onClick={() => handleDeleteSection(index)}
                  sx={{ height: 20 }}
                  alignItems="center"
                  alignContent="center"
                >
                  <Icon>delete</Icon>
                </MDBox>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={`sections[${index}].bgColor`}
                  label={t("form.bgColor")}
                  value={bgColor || "#ffffff"}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={`sections[${index}].textColor`}
                  label={t("form.textColor")}
                  value={textColor || "#ffffff"}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Description&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDEditor
                  value={(content) => {
                    const fieldName = `sections.${index}.htmlContent`;
                    setFieldValue(fieldName, content);
                  }}
                  defaultValue={defaultText}
                  maxLength={1000}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </MDBox>
    );
  }
);
export default SectionText;
