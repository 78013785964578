// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Autocomplete, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import { useUpdateClientCurrency } from "pages/myApp/myApp.hook";
import { ECurrency } from "types/client.type";

interface Props {
  currency: ECurrency;
}

const Currency = ({ currency }: Props) => {
  const { t } = useTranslation();
  const updateClientCurrency = useUpdateClientCurrency();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openAlertUpdate, setOpenAlertUpdate] = useState(false);
  const [newCurrency, setNewCurrency] = useState<ECurrency>(currency);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const currencyOptions = Object.values(ECurrency).map((value) => String(value)) as [ECurrency];

  const handleSubmit = async () => {
    try {
      if (!newCurrency) return;
      setIsSubmitting(true);
      await updateClientCurrency.mutateAsync({ currency: newCurrency });
      setIsSubmitting(false);
      setOpenAlertUpdate(true);
    } catch (error: any) {
      setIsSubmitting(false);
      setErrorAlertTitle(t("alert.update.titleError"));
      setErrorAlertContent(t("alert.update.contentError", { errorMsg: error.message }));
    }
  };

  const renderAlertUpdate = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.update.title")}
      content={t("alert.update.content")}
      open={openAlertUpdate}
      onClose={() => setOpenAlertUpdate(false)}
      close={() => setOpenAlertUpdate(false)}
      bgWhite
    />
  );

  const renderAlertErrorUpdate = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">{t("myApp.settings.currency")}</MDTypography>
        <MDTypography variant="body2">{t("myApp.settings.currencyInfoStripe")}</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              defaultValue={currency}
              options={currencyOptions}
              onChange={(e, value) => {
                setNewCurrency(value);
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
        </Grid>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt={6}
        >
          <MDBox ml="auto">
            <MDButton
              disabled={isSubmitting || !newCurrency || newCurrency === currency}
              variant="gradient"
              color="dark"
              size="small"
              onClick={() => handleSubmit()}
            >
              {t("myApp.settings.buttonCurrency")}
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "inherit",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      {renderAlertUpdate}
      {renderAlertErrorUpdate}
    </Card>
  );
};

export default Currency;
