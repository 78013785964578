import Navigation from "components/NavigationPage/Navigation";
import ListRent from "pages/rent/pages/ListRent";
import CalendarPage from "pages/order/pages/CalendarPage";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("rent.tabs.calendar"),
      content: <CalendarPage />,
      mode: "inside",
    },
    {
      label: t("rent.tabs.list"),
      content: <ListRent />,
      mode: "inside",
    },
  ];

  return (
    <Navigation stickyNavbar={true} tabs={tabs}>
      <></>
    </Navigation>
  );
};

export default Index;
