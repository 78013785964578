import { CardMedia, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useGetClientById } from "pages/client/client.hook";
import { ECurrency, SELECT_INFORMATION } from "types/client.type";

export interface Props {
  productId: string;
  quantity: number;
  price: string;
  name: string;
  url: string;
}

const CardProduct = ({ productId, quantity, price, name, url }: Props): JSX.Element => {
  const { data } = useGetClientById(SELECT_INFORMATION);
  const currency = (
    data?.settings?.stripeConnect?.defaultCurrency ||
    data?.settings?.currency ||
    ECurrency.EUR
  ).toLocaleLowerCase() as ECurrency;

  return (
    <Grid container spacing={8} alignItems="center">
      {url ? (
        <Grid item xs={12} sm={2}>
          <CardMedia
            src={url}
            component="img"
            //title={name}
            sx={{
              width: 60,
              height: 60,
              margin: 0,
              boxShadow: ({ boxShadows: { md } }: any) => md,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12} sm={6}>
        <MDBox width="max-content">
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            alignItems="center"
          >
            {name}
          </MDTypography>
          <input type="hidden" value={productId} />
        </MDBox>
      </Grid>

      <Grid item xs={12} sm={4} container justifyContent="flex-end" alignItems="center">
        <MDBox display="flex" flexDirection="column">
          <MDBox style={{ textAlign: "right" }}>
            <MDTypography variant="h6">
              {price} {currency === ECurrency.EUR ? "€" : "$"}
            </MDTypography>
          </MDBox>
          <MDBox style={{ textAlign: "right" }}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Qty : {quantity}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default CardProduct;
