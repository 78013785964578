import { ErrorMessage, Field } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

interface Props {
  label: any;
  name: string;
  disabled?: boolean;
  [key: string]: any;
}

const FormField = ({ label, name, disabled = false, ...rest }: Props): JSX.Element => {
  return (
    <MDBox mb={1.5}>
      <Field
        {...rest}
        name={name}
        as={MDInput}
        variant="standard"
        label={label}
        disabled={disabled}
        fullWidth
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {(<ErrorMessage name={name} />) as any}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default FormField;
