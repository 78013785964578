import { CardMedia, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
//import { useGetClientById } from "pages/client/client.hook";
//import { SELECT_INFORMATION } from "types/client.type";

export interface Props {
  name: string;
  pictureUrl: string;
}

const CardReservation = ({ name, pictureUrl }: Props): JSX.Element => {
  //const { data } = useGetClientById(SELECT_INFORMATION);

  return (
    <Grid container spacing={8} alignItems="center">
      {pictureUrl ? (
        <Grid item xs={12} sm={2}>
          <CardMedia
            src={pictureUrl}
            component="img"
            //title={name}
            sx={{
              width: 60,
              height: 60,
              margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12} sm={12}>
        <MDBox width="max-content">
          <MDTypography
            variant="h5"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            alignItems="center"
          >
            {name}
          </MDTypography>
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default CardReservation;
