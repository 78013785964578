import React from "react";
import { Box, Typography, Button, CircularProgress, Stack } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useGetClientById } from "pages/client/client.hook";
import { useDisconnectStripeAccount, useGetStripeConnectUrl } from "../hopelappStore.hook";
import { SELECT_INFORMATION } from "types/client.type";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";

interface StatusIndicatorProps {
  isActive: boolean;
}

interface StatusRowProps {
  label: string;
  isActive: boolean;
}

interface StripeSettingsModalProps {
  onClose: () => void;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ isActive }) => (
  <Box
    sx={{
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: isActive ? "#4caf50" : "#f44336",
    }}
  />
);

const StatusRow: React.FC<StatusRowProps> = ({ label, isActive }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      py: 1.5,
    }}
  >
    <Typography variant="body2" style={{ paddingRight: 50 }}>
      {label}
    </Typography>
    <StatusIndicator isActive={isActive} />
  </Box>
);

const StripeSettingsModal: React.FC<StripeSettingsModalProps> = ({ onClose }) => {
  const { data, isLoading } = useGetClientById(SELECT_INFORMATION);
  const getConnectUrl = useGetStripeConnectUrl();
  const disconnectStripeAccount = useDisconnectStripeAccount();
  const { t } = useTranslation();

  const handleConnect = () => {
    getConnectUrl.mutate();
  };

  const handleDisconnect = () => {
    disconnectStripeAccount.mutate();
  };

  const stripeSettings = data?.settings?.stripeConnect;
  const isConnected = stripeSettings?.enabled;
  const isPurchasable = stripeSettings?.chargesEnabled && stripeSettings?.detailsSubmitted;
  const requiresVerification = stripeSettings?.requirements?.includes(
    "individual.verification.proof_of_liveness"
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      {isConnected ? (
        <Box sx={{ py: 2 }}>
          <StatusRow label="Compte connecté" isActive={isConnected} />
          <StatusRow label="Configuration" isActive={isPurchasable} />
          <StatusRow label="Vérification d'identité" isActive={!requiresVerification} />
          <Box sx={{ mt: 3 }}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleDisconnect}
              disabled={disconnectStripeAccount.isLoading}
              fullWidth
            >
              Déconnecter
            </MDButton>
          </Box>
        </Box>
      ) : (
        <Stack spacing={3}>
          <MDTypography variant="h6" fontWeight="medium">
            {t("hopelappStore.onlinePayment.configuration.title")}
          </MDTypography>

          <MDBox>
            <MDTypography variant="body2" color="text">
              {t("hopelappStore.onlinePayment.configuration.description")}
            </MDTypography>
          </MDBox>

          <MDButton
            variant="gradient"
            color="info"
            onClick={handleConnect}
            disabled={getConnectUrl.isLoading}
            fullWidth
          >
            {getConnectUrl.isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("hopelappStore.onlinePayment.configuration.connect")
            )}
          </MDButton>
        </Stack>
      )}
    </Box>
  );
};

export default StripeSettingsModal;
