import { ReactNode } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Declaring props types for Card
interface Props {
  image?: string;
  badge: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
    label: string;
  };
  name?: ReactNode;
  email?: ReactNode;
  numberOfProducts?: string | number;
  totalPrice: number;
  images: string[];
  currency: string;
  onClick?: any;
}

function Card({
  image,
  badge,
  name,
  email,
  numberOfProducts,
  totalPrice,
  images,
  currency,
  onClick,
}: Props): JSX.Element {
  const renderMembers = images.map((member, key) => {
    const imageAlt = `image-${key}`;

    return (
      <MDAvatar
        key={imageAlt}
        src={member}
        alt={imageAlt}
        size="sm"
        sx={{
          border: ({ borders: { borderWidth }, palette: { white } }: Theme) =>
            `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1,
          mr: 0,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        }}
      />
    );
  });

  return (
    <div onClick={onClick}>
      {image && <MDBox component="img" src={image} width="100%" borderRadius="lg" mb={1} />}
      <MDBadge size="xs" color={badge.color} badgeContent={badge.label} container />
      <MDBox mt={1}>
        <MDTypography variant="body2" color="text">
          {name}
        </MDTypography>
      </MDBox>
      <MDBox mb={2}>
        <MDTypography variant="body2" color="text">
          {email}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center" color="text">
          {numberOfProducts && (
            <>
              <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                <Icon sx={{ fontWeight: "bold" }}>shopping_basket</Icon>
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{numberOfProducts}
              </MDTypography>
            </>
          )}
        </MDBox>
        <MDBox display="flex" alignItems="center" color="text">
          {totalPrice && (
            <>
              <MDTypography variant="body2" color="text" sx={{ lineHeight: 0 }}>
                <Icon sx={{ fontWeight: "bold" }}>sell</Icon>
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{totalPrice.toFixed(2)}
                {currency}
              </MDTypography>
            </>
          )}
        </MDBox>
        {renderMembers.length > 0 && <MDBox display="flex">{renderMembers}</MDBox>}
      </MDBox>
    </div>
  );
}

// Declaring default props for Card
Card.defaultProps = {
  image: "",
  progress: 0,
  numberOfProducts: "",
};

export default Card;
