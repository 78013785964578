// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useTranslation } from "react-i18next";

function Overview(): JSX.Element {
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox
        mt={5}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "50vh",
          justifyContent: "center",
        }}
      >
        <Grid>
          <MDTypography component="div" variant="h1">
            {t("thankYou.title")}
          </MDTypography>
        </Grid>
        <Grid>
          <MDTypography component="div" variant="p">
            {t("thankYou.message")}
          </MDTypography>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
