import { useState } from "react";
import MDButton from "components/MDButton";
import { triggerBuild, verifyBuild } from "services/client.service";
import { Platform } from "types/client.type";

interface BuildButtonProps {
  clientId: string;
  platform: Platform;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

const BuildButton: React.FC<BuildButtonProps> = ({ clientId, platform, onSuccess, onError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const handleBuild = async () => {
    try {
      setIsLoading(true);
      setIsVerifying(true);

      // Vérifier si le build est possible
      const verifyResponse = await verifyBuild(clientId, platform);
      setIsVerifying(false);

      if (!verifyResponse.canBuild) {
        const requirements = verifyResponse.missingRequirements?.join("\n");
        throw new Error(`Impossible de démarrer le build:\n${requirements}`);
      }

      // Déclencher le build
      const buildResponse = await triggerBuild(clientId, platform);

      if (buildResponse.success) {
        console.log('toast.success("Build démarré avec succès!")');
        onSuccess?.();

        // Si une URL de workflow est fournie, l'ouvrir dans un nouvel onglet
        if (buildResponse.workflowUrl) {
          window.open(buildResponse.workflowUrl, "_blank");
        }
      } else {
        throw new Error(buildResponse.message);
      }
    } catch (error: any) {
      const errorMessage = error.message || "Une erreur est survenue lors du build";
      console.error(`toast.error(${errorMessage})`);
      onError?.(error);
    } finally {
      setIsLoading(false);
    }
  };

  let buttonText;
  if (isVerifying) {
    buttonText = "Vérification...";
  } else if (isLoading) {
    buttonText = "Démarrage...";
  } else {
    buttonText = `Build ${platform === "ios" ? "iOS" : "Android"}`;
  }

  return (
    <MDButton
      onClick={handleBuild}
      loading={isLoading}
      disabled={isLoading}
      variant="gradient"
      color="info"
    >
      {buttonText}
    </MDButton>
  );
};

export default BuildButton;
