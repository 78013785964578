// stripe-connect.hook.ts
import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";
import {
  connectStripeAccount,
  disconnectStripeAccount,
  getStripeAccountBalance,
  getStripeAccountDetails,
  getStripeAccountStatus,
  getStripeConnectUrl,
  handleStripeCallback,
} from "./hopelappStore.service";
import { IStripeConnect } from "./hopelappStore.type";
import { KeyQueryClient } from "pages/client/client.hook";
import { SELECT_INFORMATION } from "types/client.type";

enum KeyQueryStripeConnect {
  getStripeConnect = "getStripeConnect",
  getStripeStatus = "getStripeStatus",
  getStripeBalance = "getStripeBalance",
}

// stripe-connect.hook.ts
export const useGetStripeConnectUrl = () => {
  const { clientId } = useContext(AuthContext);

  return useMutation({
    mutationFn: () => getStripeConnectUrl(clientId),
    onSuccess: (result) => {
      window.location.href = result.url;
    },
  });
};

export const useConnectStripeAccount = () => {
  return useMutation({
    mutationFn: ({ code, clientId }: { code: string; clientId: string }) =>
      connectStripeAccount(clientId, code),
  });
};

export const useHandleStripeCallback = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code: string) => handleStripeCallback(clientId, code),
    onSuccess: () => {
      queryClient.invalidateQueries([KeyQueryStripeConnect.getStripeConnect, clientId]);
    },
  });
};

export const useGetStripeAccount = (): UseQueryResult<IStripeConnect> => {
  const { clientId } = useContext(AuthContext);

  return useQuery(
    [KeyQueryStripeConnect.getStripeConnect, clientId],
    () => getStripeAccountDetails(clientId),
    {
      enabled: !!clientId,
    }
  );
};

export const useDisconnectStripeAccount = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => disconnectStripeAccount(clientId),
    onSuccess: () => {
      queryClient.invalidateQueries([KeyQueryStripeConnect.getStripeConnect, clientId]);
      queryClient.invalidateQueries([KeyQueryClient.getById, clientId, SELECT_INFORMATION]);
    },
  });
};

export const useGetStripeAccountStatus = () => {
  const { clientId } = useContext(AuthContext);

  return useQuery(
    [KeyQueryStripeConnect.getStripeStatus, clientId],
    () => getStripeAccountStatus(clientId),
    {
      enabled: !!clientId,
    }
  );
};

export const useGetStripeBalance = () => {
  const { clientId } = useContext(AuthContext);

  return useQuery(
    [KeyQueryStripeConnect.getStripeBalance, clientId],
    () => getStripeAccountBalance(clientId),
    {
      enabled: !!clientId,
    }
  );
};
