import { Icon, InputAdornment, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormField from "components/FormField/FormField";
import CropImage from "components/ImageCrop/CropImage";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDropzone from "components/MDDropzone";
import MDEditor from "components/MDEditor";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Modal, { EType } from "components/Modal/modal";
import AddAttribute from "pages/element/components/AddAttribute";
import AddCategory from "pages/element/components/AddCategory";
import ModalDelete from "pages/element/components/ModalDelete";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IAttribute, ISubCategory } from "../element.type";
import { useGetClientById } from "pages/client/client.hook";
import { SELECT_FEATURES } from "types/client.type";
import { EFeatures } from "types/features.type";

interface Props {
  subCategories: ISubCategory[];
  allAttributes: IAttribute[];
  formData: any;
  defaultImage: string;
  cropImage: (image: string) => void;
  handleDeleteProduct: () => void;
  children: React.ReactNode;
}

export const ProductUpdateInfo = ({
  subCategories,
  formData,
  defaultImage,
  allAttributes,
  cropImage,
  handleDeleteProduct,
  children,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetClientById(SELECT_FEATURES);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState<string | null>(null);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const [openFeesModal, setOpenFeesModal] = useState(false);
  const [isOnlinePaymentEnabled, setIsOnlinePaymentEnabled] = useState(
    data.features.onlinePayment?.enabled
  );

  const { formField, values, errors, touched, setFieldValue, initialValues } = formData;
  const { name, title, price, deposit, discount, description, file, attributes, inCarousel } =
    formField;
  const { description: descriptionI } = initialValues;
  const {
    name: nameV,
    title: titleV,
    price: priceV,
    deposit: depositV,
    discount: discountV,
    attributes: attributesV,
    inCarousel: inCarouselV,
    file: fileV,
  } = values;

  const calculateFinalPrice = useMemo(() => {
    if (!priceV || priceV <= 0) return 0;

    // Conversion en centimes et calcul des frais
    const priceInCents = Math.round(priceV * 100);
    const fixedFee = 30; // 30 centimes
    const percentageFee = Math.round(priceInCents * 0.055);
    const priceWithFees = (priceInCents + fixedFee + percentageFee) / 100;

    // Application de la réduction sur le prix avec frais
    const discountAmount = (priceWithFees * (discountV || 0)) / 100;
    const finalPrice = priceWithFees - discountAmount;

    return finalPrice;
  }, [priceV, discountV]);

  const twoDigitOrZero = (val: any) => {
    return Number((parseFloat(val) || 0).toFixed(2));
  };

  const handleDelete = async () => {
    try {
      setInfoDelete(t("alert.delete.content", { item: nameV }));
      await handleDeleteProduct();
      setOpenModalDelete(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: nameV }));
    }
  };

  const handleClose = () => setOpenModalDelete(false);

  const renderModalFees = (
    <Modal
      title={t("form.modalFees.title")}
      openModal={openFeesModal}
      valid={false}
      handleClose={() => setOpenFeesModal(false)}
      body={
        <MDBox mt={3} mb={1} ml={0.5}>
          <MDTypography variant="h6" mb={2}>
            {t("form.modalFees.description")}
          </MDTypography>

          <MDBox mb={2}>
            <ul style={{ paddingLeft: "20px" }}>
              <li>
                <MDTypography variant="body2">{t("form.modalFees.basePrice")}</MDTypography>
              </li>
              <li>
                <MDTypography variant="body2">
                  {t("form.modalFees.serviceFees")} (
                  <i>
                    <a href="https://stripe.com/pricing" target="_blank" rel="noopener noreferrer">
                      {t("form.modalFees.stripePricing")}
                    </a>
                  </i>
                  )
                </MDTypography>
              </li>
            </ul>
          </MDBox>

          <MDBox>
            <MDTypography variant="body2" fontWeight="bold">
              {t("form.modalFees.exampleTitle")}
            </MDTypography>
            {priceV ? (
              <MDTypography variant="body2">
                1. {t("form.modalFees.basePrice")}: {twoDigitOrZero(priceV)}€
                <br />
                {discountV > 0 && (
                  <>
                    2. {t("form.modalFees.discount")} ({discountV}%): -
                    {twoDigitOrZero((priceV * discountV) / 100)}€
                    <br />
                    3. {t("form.modalFees.priceAfterDiscount")}:{" "}
                    {twoDigitOrZero(priceV - (priceV * discountV) / 100)}€
                    <br />
                  </>
                )}
                4. {t("form.modalFees.serviceFees")}: +
                {twoDigitOrZero((priceV - (priceV * (discountV || 0)) / 100) * 0.055)}€
                <br />
                <br />
                {t("form.modalFees.finalPrice")}:{" "}
                <strong>
                  {twoDigitOrZero(
                    priceV -
                      (priceV * (discountV || 0)) / 100 +
                      (priceV - (priceV * (discountV || 0)) / 100) * 0.055
                  )}
                  €
                </strong>
              </MDTypography>
            ) : (
              <MDTypography variant="body2" color="text">
                {t("form.modalFees.enterPrice")}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
      }
    />
  );
  const renderModalDelete = (
    <Modal
      title={t("element.modalDelete.title")}
      openModal={openModalDelete}
      body={<ModalDelete name={nameV} />}
      handleValid={handleDelete}
      handleClose={handleClose}
      type={EType.delete}
    />
  );

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={infoDelete}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  return (
    <>
      <Card style={{ overflowY: "auto" }}>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MDBox display="flex" justifyContent="flex-start">
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color={inCarouselV ? "success" : "default"}
                    onClick={() => {
                      const newValue = !inCarouselV;
                      setFieldValue(inCarousel.name, newValue);
                    }}
                    iconOnly
                  >
                    <Icon>push_pin</Icon>
                  </MDButton>
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color="info"
                    onClick={() => navigate("/element/new")}
                    iconOnly
                  >
                    <Icon>add</Icon>
                  </MDButton>
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color="error"
                    onClick={() => setOpenModalDelete(true)}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                {children}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={name.type}
                  label={t("form.name")}
                  name={name.name}
                  value={nameV}
                  placeholder={name.placeholder}
                  error={errors.name && touched.name}
                  success={nameV?.length > 0 && !errors.name}
                />
              </Grid>
              {/*<Grid item xs={12} sm={6}>
                <FormField
                  type={title.type}
                  label={t("form.title")}
                  name={title.name}
                  value={titleV}
                  placeholder={title.placeholder}
                  error={errors.title && touched.title}
                  success={titleV?.length > 0 && !errors.title}
                />
              </Grid>*/}
              <Grid item xs={12} sm={6}>
                <AddCategory formData={formData} subCategories={subCategories} />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <MDBox>
                  <FormField
                    type="number"
                    label={t("form.price")}
                    name={price.name}
                    value={priceV === null || priceV === undefined ? "" : priceV}
                    placeholder={price.placeholder}
                    error={errors.price && touched.price}
                    success={priceV !== null && priceV !== undefined && !errors.price}
                  />

                  {isOnlinePaymentEnabled && (
                    <MDBox mt={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="button" color="text" fontWeight="bold">
                        {t("form.finalPrice")}: {calculateFinalPrice.toFixed(2)} €
                      </MDTypography>
                      <MDTypography
                        component="a"
                        variant="caption"
                        color="info"
                        fontWeight="regular"
                        sx={{ cursor: "pointer", textDecoration: "underline" }}
                        onClick={() => setOpenFeesModal(true)}
                      >
                        {t("form.modalFees.title")}
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              </Grid>

              {/* Colonne droite */}
              <Grid item xs={12} md={6}>
                <MDBox>
                  {/* Réduction */}
                  <FormField
                    type="number"
                    label={t("form.discount")}
                    name={discount.name}
                    value={discountV === null || discountV === undefined ? "0" : discountV}
                    placeholder="0-99%"
                    InputProps={{
                      min: 0,
                      max: 99,
                      step: 1,
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      onChange: (e: any) => {
                        const value = parseInt(e.target.value);
                        if (value >= 0 && value <= 99) {
                          setFieldValue(discount.name, value);
                        } else if (value < 0) {
                          setFieldValue(discount.name, "");
                        } else if (value > 99) {
                          setFieldValue(discount.name, 99);
                        } else {
                          setFieldValue(discount.name, "");
                        }
                      },
                    }}
                    error={errors.discount && touched.discount}
                    success={!errors.discount}
                  />

                  {/* Acompte */}
                  <FormField
                    type="number"
                    label={
                      <>
                        {t("form.deposit")}
                        <Tooltip title={t("form.depositHelp")}>
                          <Icon sx={{ ml: 1, cursor: "help" }}>help_outline</Icon>
                        </Tooltip>
                      </>
                    }
                    name={deposit.name}
                    value={depositV === null || depositV === undefined ? "0" : depositV}
                    placeholder="0-99%"
                    InputProps={{
                      min: 0,
                      max: 99,
                      step: 1,
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      onChange: (e: any) => {
                        if (!isOnlinePaymentEnabled) return;
                        const value = parseInt(e.target.value);
                        if (value >= 0 && value <= 99) {
                          setFieldValue(deposit.name, value);
                        } else if (value < 0) {
                          setFieldValue(deposit.name, "");
                        } else if (value > 99) {
                          setFieldValue(deposit.name, 99);
                        } else {
                          setFieldValue(deposit.name, "");
                        }
                      },
                    }}
                    error={errors.deposit && touched.deposit}
                    success={!errors.deposit}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {/*<MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={price.type}
                  label={t("form.price")}
                  name={price.name}
                  // Modifier cette ligne pour gérer explicitement les valeurs null/undefined
                  value={priceV === null || priceV === undefined ? "" : priceV}
                  placeholder={price.placeholder}
                  error={errors.price && touched.price}
                  // Modifier la condition de success pour prendre en compte les valeurs null/undefined
                  success={
                    priceV !== null && priceV !== undefined && priceV.length > 0 && !errors.price
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDBox mb={3}>
                  <Grid item xs={12}>
                    <MDBox display="flex" alignItems="center">
                      <MDTypography variant="button" color="text" mr={1}>
                        {t("form.finalPrice")}: {calculateFinalPrice.toFixed(2)} €
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" alignItems="right">
                      <MDTypography
                        component="a"
                        variant="caption"
                        color="info"
                        fontWeight="regular"
                        sx={{ cursor: "pointer", textDecoration: "underline" }}
                        onClick={() => setOpenFeesModal(true)}
                      >
                        How fees work?
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={deposit.type}
                  label={t("form.deposit")}
                  name={deposit.name}
                  value={depositV === null || depositV === undefined ? "" : depositV}
                  placeholder={deposit.placeholder}
                  error={errors.deposit && touched.deposit}
                  success={depositV !== null && depositV !== undefined && !errors.deposit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={discount.type}
                  label={t("form.discount")}
                  name={discount.name}
                  value={discountV === null || discountV === undefined ? "" : discountV}
                  placeholder={discount.placeholder}
                  error={errors.discount && touched.discount}
                  success={discountV !== null && discountV !== undefined && !errors.discount}
                />
              </Grid>
            </Grid>
          </MDBox>*/}
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  {t("form.image")}
                </MDTypography>
                {useMemo(
                  () => (
                    <MDDropzone
                      options={{
                        addRemoveLinks: false,
                        maxFiles: 1,
                        acceptedFiles: "image/png, image/jpg, image/jpeg",
                      }}
                      defaultImageUrl={defaultImage}
                      onChange={(fileImage) => setFieldValue(file.name, fileImage)}
                      error={errors.file}
                    />
                  ),
                  [defaultImage]
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  {t("form.imagePosition")}
                </MDTypography>

                <CropImage
                  image={fileV || defaultImage}
                  onChange={(value: any) => cropImage(value)}
                  // crop={crop}
                  // zoom={zoom}
                  // aspect={4 / 3}
                  // onCropChange={setCrop}
                  // onCropComplete={onCropComplete}
                  // onZoomChange={setZoom}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Description&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDEditor
                  value={(content) => setFieldValue(description.name, content)}
                  defaultValue={descriptionI}
                  maxLength={1000}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <AddAttribute
              currentAttributes={attributesV}
              allAttributes={allAttributes}
              onChange={(newAttributes) => setFieldValue(attributes.name, newAttributes)}
            />
          </MDBox>
        </MDBox>
      </Card>
      {renderModalFees}
      {renderModalDelete}
      {renderAlertDelete}
      {renderAlertError}
    </>
  );
};

export default ProductUpdateInfo;
// export default React.memo(ProductUpdateInfo);
