import { FormControl, Grid, Icon, MenuItem, Select } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Modal, { EType } from "components/Modal/modal";
import DataTable from "examples/Tables/DataTable";
import ModalDelete from "pages/order/components/ModalDelete";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useDeleteReservation,
  useGetAllReservation,
  useUpdateReservation,
} from "../../reservation/reservations.hook";
import { IReservation, ETypeAction, IStatusReservation } from "../../reservation/reservation.type";
// eslint-disable-next-line import/extensions
import ModalInfoAppointment from "../components/ModalInfoAppointment";
import { useGetClientById } from "pages/client/client.hook";
import { SELECT_INFORMATION } from "types/client.type";
import moment from "moment";

const ListeReservation = (): JSX.Element => {
  const { t } = useTranslation();
  moment.locale(t("lang"));
  const deleteReservation = useDeleteReservation();
  const modalCreateReservationRef = useRef(null);
  const { data, isLoading } = useGetAllReservation();
  const { data: dataClient } = useGetClientById(SELECT_INFORMATION);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [typeAction, setTypeAction] = useState<ETypeAction>(ETypeAction.CREATE);
  const [openAlertCreateReservation, setOpenAlertCreateReservation] = useState(false);
  const [selectReservation, setSelectReservation] = useState<IReservation | null>(null);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const [errorAlertTitleReservation, setErrorAlertTitleReservation] = useState<string | null>(null);
  const [errorAlertContentReservation, setErrorAlertContentReservation] = useState<string | null>(
    null
  );
  const updateReservation = useUpdateReservation();

  const dataTableReservation = {
    columns: [
      {
        Header: t("appointment.headerName"),
        accessor: "name",
        width: "25%",
        Cell: ({ row }: any) => (
          <span style={{ cursor: "pointer" }} onClick={() => handleOpenReservation(row.original)}>
            {row.original.name}
          </span>
        ),
      },
      {
        Header: t("appointment.headerEmail"),
        accessor: "email",
        width: "25%",
        Cell: ({ row }: any) => (
          <span style={{ cursor: "pointer" }} onClick={() => handleOpenReservation(row.original)}>
            {row.original.email}
          </span>
        ),
      },
      {
        Header: t("appointment.headerStartDate"),
        accessor: (row: any) => moment(row?.createdAt).format("MM/DD/YYYY HH:mm"),
        width: "15%",
        Cell: ({ row }: any) => (
          <span style={{ cursor: "pointer" }} onClick={() => handleOpenReservation(row.original)}>
            {moment(row?.original.start).format("MM/DD/YYYY HH:mm")}
          </span>
        ),
      },
      {
        Header: t("appointment.headerEndDate"),
        accessor: (row: any) => moment(row?.createdAt).format("MM/DD/YYYY HH:mm"),
        width: "15%",
        Cell: ({ row }: any) => (
          <span style={{ cursor: "pointer" }} onClick={() => handleOpenReservation(row.original)}>
            {moment(row?.original.end).format("MM/DD/YYYY HH:mm")}
          </span>
        ),
      },
      {
        Header: t("appointment.action"),
        accessor: "status",
        align: "right",
        sorted: false,
        Cell: ({ row }: any) => {
          return (
            <MDBox display="flex" justifyContent="flex-end" textAlign="right">
              <MDBox>
                <FormControl sx={{ mt: 1, width: "100%" }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={row.original.status}
                    defaultValue={row.original.status}
                    onChange={(value) => {
                      if (value.target.value != IStatusReservation.PENDING)
                        handleActionUpdate(row.original, value.target.value as IStatusReservation);
                      else {
                        setErrorAlertTitle("Action non permise.");
                        setErrorAlertContent(
                          "Il est impossible de réattribuer le statut En attente à un rendez-vous."
                        );
                      }
                    }}
                    label={t("form.nameRedirection")}
                  >
                    <MenuItem value={IStatusReservation.PENDING}>
                      {t("appointment.status.pending")}
                    </MenuItem>
                    <MenuItem value={IStatusReservation.CANCEL}>
                      {t("appointment.status.cancel")}
                    </MenuItem>
                    <MenuItem value={IStatusReservation.CONFIRM}>
                      {t("appointment.status.confirm")}
                    </MenuItem>
                    <MenuItem value={IStatusReservation.REFUSE}>
                      {t("appointment.status.refuse")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
              <MDButton
                variant="text"
                color="error"
                onClick={() => handleActionDelete(row.original)}
              >
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          );
        },
      },
    ],
    rows: data,
  };

  const handleActionUpdate = async (reservation: IReservation, status: IStatusReservation) => {
    try {
      await updateReservation.mutateAsync({
        reservationId: reservation._id,
        data: { status },
      });
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleOpenReservation = (reservation: IReservation) => {
    setSelectReservation(reservation);
    setTypeAction(ETypeAction.UPDATE);
    setOpenModalCreate(true);
  };

  const handleActionDelete = (reservation: IReservation) => {
    setSelectReservation(reservation);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteReservation.mutateAsync(selectReservation?._id);
      setOpenModal(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: selectReservation?.title }));
    }
  };

  const handleClose = () => setOpenModal(false);

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={t("alert.delete.content", { item: selectReservation?.title })}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const renderModalDelete = (
    <Modal
      title={t("appointment.modalDelete.title")}
      openModal={openModal}
      body={<ModalDelete name={selectReservation?.title} />}
      handleValid={handleDelete}
      handleClose={handleClose}
      type={EType.delete}
    />
  );

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!data);
    }, 2000);
  };

  const renderCreateOrUpdateReservation = (
    <Modal
      title={t(`appointment.modal.${typeAction}.title`)}
      openModal={openModalCreate}
      body={
        <ModalInfoAppointment
          currentAppointment={selectReservation}
          ref={modalCreateReservationRef}
        />
      }
      handleClose={() => setOpenModalCreate(false)}
      valid={false}
    />
  );

  return (
    <>
      <MDBox pt={1} pb={3}>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {data ? (
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={7}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {t("appointment.titleTable")}
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ textAlign: "right" }}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={(e: any) => e.preventDefault()} // TODO : Create a reservation from AGI
                    >
                      <Icon>add</Icon>&nbsp; {t("appointment.addAppointment")}
                    </MDButton>
                  </Grid>
                </MDBox>

                <DataTable table={dataTableReservation} canSearch />
              </Card>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("appointment.errorGetReservation")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </MDBox>

      {renderModalDelete}
      {renderAlertDelete}
      {renderAlertError}
      {renderCreateOrUpdateReservation}
    </>
  );
};

export default React.memo(ListeReservation);
